import { Link } from "react-router-dom";
import { NavBar } from "../components/navbar/NavBar";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import mobile from "../images/Group 94.png";
import mobileGiff from "../images/Giff01.gif";
import desktopView from "../images/Desktop view + map.png";
import { useWindowSize } from "../hooks/window";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "25vw",
    height: "30vh",
    marginLeft: "10vw",
    // gridTemplateColumns: "repeat(5, 2fr)",
  },
  integrationText: {
    marginTop: "0vh",
    fontSize: 43,
    fontFamily: "Avant Garde Gothic - Book",
    color: "white",
    fontWeight: "bold",
  },
  signUpButton: {
    color: "white",
    // width: "38%",
    // marginLeft: 0,
  },
  bottomText: {
    display: "flex",
    fontFamily: "Avant Garde Gothic - Book",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "25vw",
    height: "15vh",
  },
  textItem: {
    display: "grid",
    gap: 30,
    marginLeft: "10vw",
    marginTop: "3vh",
    width: "25vw",
  },
  exploreLink: {
    marginLeft: 0,
    color: "#EF007A",
    fontWeight: "bold",
    textDecoration: "none",
    textDecorationColor: "#EF007A",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  exploreBubble: {
    position: "absolute",
    bottom: 50,
    right: 150,
    marginTop: "5vh",
    border: "1pt solid #EF007A",
    height: "100px",
    width: "100px",
    borderRadius: "100px 100px",
    display: "flex",
    cursor: "pointer",
    backgroundColor: "white",
    textDecoration: "none",
    // opacity: 0.8,

    boxShadow: "1px 1px 1px 1px #E1E8ED",
    "&:hover": {
      border: "2pt solid #EF007A",
      boxShadow: "0px 0px 0px 0px #E1E8ED",
    },
  },
  exploreBubbleText: {
    width: "50px",
    lineHeight: 1.5,
    textAlign: "center",
    margin: "auto",
    color: "#EF007A",
    textDecoration: "none",
    textDecorationColor: "#EF007A",
  },
  mobileContainter: {
    position: "absolute",
    height: "85vh",
    top: "23vh",
    left: "37vw",
  },
  mobileImage: {
    position: "absolute",
    height: "calc(100% - 230px)",
    objectFit: "cover",
    bottom: 142,
    left: 45,
  },
  mobileImageBack: {
    position: "absolute",
    height: "calc(100% - 50px)",
    objectFit: "cover",
    bottom: 0,
    left: 0,
  },
  desktopContainter: {
    position: "absolute",
    height: "950px",
    top: "0vh",
    left: "48vw",
  },
  desktopImage: {
    position: "absolute",
    height: "calc(100% - 190px)",
    objectFit: "cover",
    bottom: 0,
    left: 0,
  },
}));

export const LandingPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [width] = useWindowSize();

  return (
    <div style={{ position: "relative", overflow: "hidden", height: "100vh" }}>
      <div
        style={{
          background:
            "linear-gradient(312deg, rgba(2,0,36,1) 11%, rgba(201,95,221,1) 72%, rgba(144,200,255,1) 97%)",
          height: "65vh",
          fontFamily: "sans-serif",
        }}
      >
        <NavBar mode="light" width={width} />
        <div className={classes.titleContainer}>
          <div className={classes.integrationText}>
            Insights and Collaboration Engine
          </div>
          <div className={classes.signUpButton}>
            {!localStorage.getItem("token") && (
              <Button
                style={{ marginLeft: 0 }}
                color="inherit"
                variant="outlined"
                onClick={() => {
                  history.push("/signup");
                }}
              >
                SIGN UP
              </Button>
            )}
          </div>
        </div>
        <div className={classes.desktopContainter}>
          <img
            src={desktopView}
            alt="desktop view"
            className={classes.desktopImage}
          ></img>
        </div>
        <div className={classes.mobileContainter}>
          <img
            src={mobile}
            alt="mobile back"
            className={classes.mobileImageBack}
          ></img>
          <img
            src={mobileGiff}
            alt="mobile giff"
            className={classes.mobileImage}
          ></img>
        </div>
        <Link className={classes.exploreBubble} to="/app">
          <span className={classes.exploreBubbleText}>Explore now</span>
        </Link>
      </div>
      <div>
        <div className={classes.textItem}>
          <div style={{ color: "#627592", lineHeight: 1.7 }}>
            The Insights and Collaboration Engine supports development of NHS
            Integrated Care Systems by enabling a data-driven view of the
            current strength of integration for a selected ICS when compared to
            other ICSs across England
          </div>
          <Link style={{ textDecoration: "none" }} to="/app">
            <span className={classes.exploreLink}>
              Explore now <span>&#62;</span>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};
