import React from "react";
import { Theme } from "@carnallfarrar/fitzroy";
import { Button, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  Button: {
    width: "140px !important",
    height: "60px !important",
    fontSize: "11px !important",
    "&:disabled": {
      border: "1px solid #CBD6E5 !important",
      color: "#CBD6E5 !important",
    },
  },
}));

export interface ChasmSelectionProps {
  onSelect: (chasm: string) => void;
}

export type ChasmColorMapProps = {
  [key: string]: {
    color: string;
    highlight: string;
    variant?: Theme.VariantType;
  };
};

export const chasmColorMap: ChasmColorMapProps = {
  overall_index: {
    color: "#EF007A",
    highlight: "#8A0460",
    variant: "primary",
  },
  physical_and_mental_health: {
    color: "#FABF27",
    highlight: "#F5711A",
    variant: "secondary",
  },
  community_and_hospital_care: {
    color: "#49AE70",
    highlight: "#295C3B",
    variant: "tertiary",
  },
  health_and_social_care: {
    color: "#973AA4",
    highlight: "#4D227A",
    variant: "quaternary",
  },
};

export type Chasms =
  | "overall_index"
  | "physical_and_mental_health"
  | "community_and_hospital_care"
  | "health_and_social_care";

export const ChasmSelection: React.FC<ChasmSelectionProps> = ({ onSelect }) => {
  const [selected, setSelected] = React.useState("overall_index");

  const classes = useStyles({ selected });
  const accessToken = localStorage.getItem("token");

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        gap: 10,
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <Button
        className={classes.Button}
        disableRipple
        style={{
          backgroundColor:
            selected === "overall_index"
              ? chasmColorMap.overall_index.color
              : "white",
          color: selected === "overall_index" ? "white" : "#44546A",
          border: selected === "overall_index" ? "none" : "1px solid #8F9EB5",
          margin: 0,
          boxShadow: "none",
        }}
        variant="contained"
        onClick={() => {
          setSelected("overall_index");
          onSelect("overall_index");
        }}
      >
        Overall Index
      </Button>
      <Tooltip
        title="Sign-in to see the data"
        arrow
        disableHoverListener={!!accessToken}
      >
        <div>
          <Button
            disableRipple
            className={classes.Button}
            variant="contained"
            disabled={!accessToken}
            style={{
              backgroundColor:
                selected === "physical_and_mental_health"
                  ? chasmColorMap.physical_and_mental_health.color
                  : "white",
              color:
                selected === "physical_and_mental_health" ? "white" : "#44546A",
              border:
                selected === "physical_and_mental_health"
                  ? "none"
                  : "1px solid #8F9EB5",
              boxShadow: "none",
              margin: 0,
            }}
            onClick={() => {
              setSelected("physical_and_mental_health");
              onSelect("physical_and_mental_health");
            }}
          >
            Physical and Mental Health
          </Button>
        </div>
      </Tooltip>

      <Tooltip
        title="Sign-in to see the data"
        arrow
        disableHoverListener={!!accessToken}
      >
        <div>
          <Button
            disableRipple
            className={classes.Button}
            variant="contained"
            disabled={!accessToken}
            style={{
              backgroundColor:
                selected === "community_and_hospital_care"
                  ? chasmColorMap.community_and_hospital_care.color
                  : "white",
              color:
                selected === "community_and_hospital_care"
                  ? "white"
                  : "#44546A",
              border:
                selected === "community_and_hospital_care"
                  ? "none"
                  : "1px solid #8F9EB5",
              boxShadow: "none",
              margin: 0,
            }}
            onClick={() => {
              setSelected("community_and_hospital_care");
              onSelect("community_and_hospital_care");
            }}
          >
            Community and Hospital Care
          </Button>
        </div>
      </Tooltip>
      <Tooltip
        title="Sign-in to see the data"
        arrow
        disableHoverListener={!!accessToken}
      >
        <div>
          <Button
            disableRipple
            className={classes.Button}
            variant="contained"
            disabled={!accessToken}
            style={{
              backgroundColor:
                selected === "health_and_social_care"
                  ? chasmColorMap.health_and_social_care.color
                  : "white",
              color:
                selected === "health_and_social_care" ? "white" : "#44546A",
              border:
                selected === "health_and_social_care"
                  ? "none"
                  : "1px solid #8F9EB5",
              boxShadow: "none",
              margin: 0,
            }}
            onClick={() => {
              setSelected("health_and_social_care");
              onSelect("health_and_social_care");
            }}
          >
            Health and Social Care
          </Button>
        </div>
      </Tooltip>
    </div>
  );
};
