import { useState } from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import { Login } from "./Login";
import { ForgotPassword } from "./ForgotPassword";

type Props = DialogProps & {
  title: string;
  signIn: string;
};

export const SignInModal: React.FC<Props> = ({ title, signIn, ...props }) => {
  // const token = localStorage.getItem("token");
  const [loginState, setLoginState] = useState(signIn);
  return (
    <Dialog
      // style={{ backgroundColor: "#F5F8FA" }}
      fullWidth
      maxWidth="xs"
      {...props}
    >
      {loginState === "login" ? (
        <Login
          message="Sign in with your email"
          setLoginState={setLoginState}
        />
      ) : (
        <ForgotPassword
          message={"Enter your email to reset password"}
          setLoginState={setLoginState}
        />
      )}
    </Dialog>
  );
};
