import { makeStyles } from "@mui/styles";
import InfoIcon from "@mui/icons-material/Info";
import React from "react";

const useStyles = makeStyles((theme: any) => ({
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    margin: "12px 20px",
    maxWidth: 300,
    minHeight: 90,
    "@media screen and (max-width: 1000px)": {
      margin: "12px 14px",
    },
  },
  input: {
    width: "292px",
    height: "40px",
    backgroundColor: "rgb(255, 255, 255)",
    border: "1px solid #26A5B8",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: 10,
    fontSize: 16,
    color: "#44546A",
    outline: "none",
    "&::placeholder": {
      color: "#AEBACD",
      fontSize: 14,
    },
    "&:focus": {
      boxShadow: "inset 0px 0px 0px 1px #26A5B8",
    },
    "@media screen and (max-width: 1000px)": {
      width: "260px",
    },
  },
  label: {
    fontFamily: "Avant Garde Gothic - Book",
    fontSize: 13,
    color: "#44546A",
    marginLeft: 8,
  },
  required: {
    fontFamily: "Avant Garde Gothic - Book",
    fontSize: 20,
    color: "#26A5B8",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 5,
    marginLeft: 8,
  },
  infoIcon: {
    color: "#26A5B8",
  },
  infoText: {
    fontFamily: "Avant Garde Gothic - Book",
    color: "#44546A",
    fontSize: 11,
    marginTop: 3,
  },
  error: {
    color: "#EF007A",
    fontSize: 11,
    marginLeft: 8,
  },
}));

interface FormInputProps {
  formLabel?: string;
  required: boolean;
  title: string;
  value: string;
  type: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur?: (e: any) => void;
  error: string | undefined;
  infoText?: string;
  placeholder?: string;
}

export const FormInput = ({
  formLabel,
  required,
  title,
  value,
  type,
  handleChange,
  handleBlur,
  error,
  infoText,
  placeholder,
}: FormInputProps) => {
  const classes = useStyles();
  return (
    <div className={classes.inputContainer}>
      {formLabel ? (
        <div className={classes.label}>
          {formLabel}{" "}
          <span className={classes.required}>{required && "*"}</span>
        </div>
      ) : null}
      <input
        placeholder={placeholder}
        className={classes.input}
        id={title}
        name={title}
        value={value}
        type={type}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {infoText && (
        <div className={classes.infoContainer}>
          <InfoIcon fontSize="small" className={classes.infoIcon} />{" "}
          <span className={classes.infoText}>{infoText}</span>
        </div>
      )}

      <div className={classes.error}>{error}</div>
    </div>
  );
};
