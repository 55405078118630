import logolight from "./logo_light.png";

export const SelectionRequired = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 100,
      }}
    >
      <img src={logolight} alt="CF logo light" />
    </div>
  );
};
