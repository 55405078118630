import React from "react";
import { ThemeProvider } from "styled-components";
import { Charts, Theme } from "@carnallfarrar/fitzroy";
import {
  CCGFeature,
  ICSFeature,
  isCCGProperties,
} from "../selectors/comparison";
import ChartTheme from "../config/ChartTheme";
import { ChartTooltip } from "./ChartTooltip";

const { HorizontalBarChart } = Charts;

interface Props extends Theme.VariantProps {
  data?: {
    name: string;
    value: number;
  }[];
  selectedFeature?: ICSFeature | CCGFeature;
}

export const CompareChart: React.FC<Props> = ({
  data,
  variant,
  selectedFeature,
}) => {
  if (!data) {
    return null;
  }

  let selectedRegionName: string | undefined;

  if (selectedFeature) {
    selectedRegionName = isCCGProperties(selectedFeature.properties)
      ? selectedFeature.properties.CCG21NM
      : selectedFeature.properties.ics_name;
  }
  const mappedData = data.map((point) => ({
    label: point.name,
    value: point.value,
  }));

  return (
    <div style={{ position: "relative" }}>
      <ThemeProvider theme={ChartTheme}>
        <HorizontalBarChart
          width={520}
          height={400}
          data={mappedData || []}
          xLabel="Index value"
          variant={variant}
          tooltip={(args) => ChartTooltip({ ...args, variant })}
          showXTicks={false}
          selectedLabel={selectedRegionName}
        />
      </ThemeProvider>
    </div>
  );
};
