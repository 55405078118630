import { makeStyles } from "@mui/styles";
import React, { useState } from "react";

const useStyles = makeStyles((theme: any) => ({
  switchContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
  },
  switch: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.primary[500],
    border: `1px solid ${theme.palette.primary[500]}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    margin: "10px 4px",
    marginTop: 0,
    borderRadius: 5,
    width: 28,
    height: 28,
    filter: "drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.16))",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  selectedSwitch: {
    backgroundColor: theme.palette.primary[500],
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    borderRadius: 5,
    margin: "10px 4px",
    marginTop: 0,
    width: 28,
    height: 28,
    filter: "drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.16))",
  },
}));

export const Info: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div
      style={{
        maxWidth: 48,
        fontSize: 10,
        color: "#627592",
        width: "100%",
        textAlign: "center",
        backgroundColor: "white",
        padding: 2,
        borderRadius: 2,
      }}
    >
      {text}
    </div>
  );
};

export interface Option {
  label: JSX.Element;
  value: string;
  info?: string;
}

interface Props {
  options: Option[];
  selected?: Option;
  onChange: (val: Option) => any;
  position?: string;
  open?: boolean;
}

export const OptionSelection: React.FC<Props> = ({
  options,
  onChange,
  selected,
  position = "right",
  open,
}) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const [optionHover, setOptionHover] = useState<string | undefined>();
  selected =
    selected &&
    (options.find(({ value }) => value === selected?.value) as Option);
  return (
    <div
      style={{
        position: "absolute",
        right: position === "right" ? 0 : "inherit",
        left: position === "left" ? 0 : "inherit",
        display: "flex",
        padding: 10,
        paddingTop: 0,
        zIndex: 999,
        cursor: "pointer",
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {(hover || open) &&
        position === "right" &&
        options.map((opt) => {
          if (opt.value === selected?.value) {
            return null;
          }

          return (
            <div key={opt.value}>
              <div
                className={classes.switch}
                onClick={() => onChange(opt)}
                onMouseEnter={() => setOptionHover(opt.info)}
                onMouseLeave={() => setOptionHover(undefined)}
              >
                {opt.label}
              </div>
              {opt.info && opt.info === optionHover && <Info text={opt.info} />}
            </div>
          );
        })}
      {selected && (
        <div className={classes.switchContainer}>
          <div
            className={classes.selectedSwitch}
            onClick={() => onChange(selected as Option)}
          >
            {selected.label}
          </div>
          {selected.info && hover && <Info text={selected.info} />}
        </div>
      )}
      {(hover || open) &&
        position === "left" &&
        options.map((opt) => {
          if (opt.value === selected?.value) {
            return null;
          }

          return (
            <div key={opt.value} className={classes.switchContainer}>
              <div
                className={classes.switch}
                onClick={() => onChange(opt)}
                onMouseEnter={() => setOptionHover(opt.info)}
                onMouseLeave={() => setOptionHover(undefined)}
              >
                {opt.label}
              </div>
              {opt.info && opt.info === optionHover && <Info text={opt.info} />}
            </div>
          );
        })}
    </div>
  );
};
