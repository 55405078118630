import { Feature, FeatureCollection, MultiPolygon, Polygon } from "@turf/turf";
import { Chasms } from "../components/ChasmSelection";
import { Option } from "../components/OptionSelection";
import { CCGMetric } from "../http/ccg-metrics";
import { Metric } from "../http/metrics";
import { isCCGMetric } from "../http/metrics-helper";

interface CCGProperties {
  OBJECTID: number;
  CCG21CD: string;
  CCG21NM: string;
  BNG_E: number;
  BNG_N: number;
  LONG: number;
  LAT: number;
  Shape__Area: number;
  Shape__Length: number;
}

interface ICSProperties {
  id: string;
  ics_name: string;
}


export type ICSFeature = Feature<Polygon | MultiPolygon, ICSProperties>;
export type CCGFeature = Feature<Polygon | MultiPolygon, CCGProperties>;

export type ICSGeoJSON = FeatureCollection<
  Polygon | MultiPolygon,
  ICSProperties
>;
export type CCGGeoJSON = FeatureCollection<
  Polygon | MultiPolygon,
  CCGProperties
>;

export const isCCGProperties = (
  properties: ICSProperties | CCGProperties
): properties is CCGProperties => {
  return !!(properties as any).OBJECTID;
};

const similarComparison = (
  metrics: (Metric | CCGMetric)[],
  selectedRegion: Metric | CCGMetric
) => {
  const sortedMetrics = metrics.sort((a, b) => {
    return a.IMD > b.IMD ? 1 : -1;
  });

  const index = sortedMetrics.findIndex(
    ({ id }: Metric | CCGMetric) => id === selectedRegion.id
  );

  let min = Math.max(index - 3, 0);
  let max = Math.min(min + 7, metrics.length);
  min = max - 7;

  return sortedMetrics.slice(min, max);
};

const closeComparison = (
  metrics: (Metric | CCGMetric)[],
  selectedRegion: Metric | CCGMetric
) => {
  if (isCCGMetric(selectedRegion)) {
    const selectedMetric = metrics.find(({ id }) => {
      return id === selectedRegion.id;
    }) as CCGMetric;
    metrics = (metrics as Array<CCGMetric>).filter(({ ics_id }) => {
      return ics_id === selectedMetric.ics_id;
    });
  } else {
    const selectedMetric = metrics.find(({ id }) => {
      return id === selectedRegion.id;
    }) as Metric;
    metrics = (metrics as Array<Metric>).filter(({ region_code }) => {
      return region_code === selectedMetric.region_code;
    });
  }
  return metrics;
};

const customComparison = (
  metrics: (Metric | CCGMetric)[],
  customFeatures: (ICSFeature | CCGFeature)[]
) => {
  const customIds = customFeatures.map((feature) => {
    return isCCGProperties(feature.properties)
      ? feature.properties.CCG21NM
      : feature.properties.ics_name;
  });

  return metrics.filter((metric) => {
    if (isCCGMetric(metric)) {
      return customIds.includes(metric.ccg_name);
    } else {
      return customIds.includes(metric.ics_name);
    }
  });
};

export const getFilteredMetrics = (
  metrics: (Metric | CCGMetric)[],
  selectedRegion: Metric | CCGMetric,
  comparison: Option,
  chasm: Chasms,
  customFeatures: (ICSFeature | CCGFeature)[]
) => {
  if (comparison.value === "similarcomparison") {
    return similarComparison(metrics, selectedRegion);
  }

  if (comparison.value === "closecomparison") {
    return closeComparison(metrics, selectedRegion);
  }

  if (comparison.value === "customcomparison") {
    return customComparison(metrics, customFeatures);
  }

  return metrics;
};
