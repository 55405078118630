import { makeStyles } from "@mui/styles";
import { Signup } from "../components/Signup";

import { NavBar } from "../components/navbar/NavBar";
import { useWindowSize } from "../hooks/window";

const useStyles = makeStyles((theme: any) => ({
  navButtonLight: {
    color: "white",
    fontWeight: "bold",
  },
  signUpCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 10,
    marginBottom: 20,
    overflow: "hidden",
  },
  signupWrapper: {
    background: "rgb(255, 255, 255, 0.7)",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.16)",
    borderRadius: "12px",
    padding: 20,
    display: "flex",
    overflow: "hidden",
    "@media screen and (max-width: 1000px)": {
      padding: 10,
    },
  },
}));

export const SignUpPage = () => {
  const classes = useStyles();
  const [width] = useWindowSize();

  return (
    <div
      style={{
        background:
          "linear-gradient(338deg, rgba(2,0,36,1) 0%, rgba(72,34,101,1) 0%, rgba(87,41,115,1) 12%, rgba(118,56,144,1) 22%, rgba(172,81,194,1) 36%, rgba(185,87,206,1) 41%, rgba(201,95,221,1) 52%, rgba(198,100,223,1) 54%, rgba(144,200,255,1) 91%)",
        height: "100vh",
        fontFamily: "sans-serif",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <NavBar mode="light" width={width} hideSignIn />
      <div className={classes.signUpCard}>
        <div className={classes.signupWrapper}>
          <Signup />
        </div>
      </div>
    </div>
  );
};
