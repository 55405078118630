import InfoIcon from "@mui/icons-material/Info";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Option } from "./OptionSelection";

const useStyles = makeStyles((theme: any) => ({
  container: {
    backgroundColor: theme.palette.grey[50],
    display: "flex",
    padding: 12,
    borderRadius: 5,
    marginBottom: 20,
  },
}));

interface InfoProps {
  value?: number;
  regionExtendedName?: string;
  mode: Option;
  selectionRequired: boolean;
  chasm: string;
  onUnselect: () => void;
  comparison: Option;
}

const getSelectionText = (mode: Option, comparison: Option) => {
  if (comparison.value === "similarcomparison") {
    return (
      <p>
        Please select a {mode.label} and select the chart or the table to see
        similar values
      </p>
    );
  }

  if (comparison.value === "closecomparison") {
    return (
      <p>
        Please select a {mode.label} to see its relative position within the
        same {mode.value === "ics" ? "region" : "ICS"}
      </p>
    );
  }

  if (comparison.value === "customcomparison") {
    return (
      <p>
        Please select a combination of {mode.label} on the map and see how they
        compare on the chart or table view
      </p>
    );
  }
};

export const Info: React.FC<InfoProps> = ({
  value,
  onUnselect,
  regionExtendedName,
  selectionRequired,
  mode,
  comparison,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {!value ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <InfoIcon color="primary" style={{ marginRight: 12 }} />
          {selectionRequired ? (
            getSelectionText(mode, comparison)
          ) : (
            <p>
              Please select an area on the map and see the related data. You can
              filter the detailed data after signin
            </p>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div style={{ color: "#666", marginBottom: 4, flex: "none" }}>
            {regionExtendedName}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
            }}
          >
            <div style={{ display: "flex", flex: 1 }}>
              <div style={{ fontSize: 52, fontWeight: 500, lineHeight: 1.2 }}>
                {value.toFixed(2)}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  color: "#666",
                  marginLeft: 12,
                }}
              >
                <div>Max: 1</div>
                <div>Min: -1</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: "none",
              }}
            >
              <Button variant="outlined" onClick={onUnselect}>
                Unselect
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
