import { MetricNames } from "./common-metrics";

export interface Metric extends MetricNames {
  id: string;
  ics_name: string;
  community_and_hospital_care: number;
  health_and_social_care: number;
  physical_and_mental_health: number;
  overall_index: number;
  region_code: string;
  region_name: string;
  IMD:number;
}

export interface ExtendedMetrics {
  ics_id: string;
  ics_name: string;
  chasm: string;
  metric_id: string;
  metric_name: string;
  value: number;
}

export interface Dictionary {
  metric_id: string;
  metric_short_name: string;
  metric_fe_name: string;
  description: string;
  unit: string;
  data_source: string;
  chasm: string;
}

export const getMetrics = (mode: string) => {
  const accessToken = localStorage.getItem("token");

  return fetch(
    `${process.env.REACT_APP_API_URL!}/${
      mode === "ics" ? "metrics" : "ccg-metrics"
    }`,
    {
      headers: {
        authorization: accessToken ? `bearer ${accessToken}` : "na",
      },
    }
  ).then((res) => {
    if (res.status === 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }

    return res.json();
  });
};
