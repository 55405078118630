import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { useModal } from "mui-modal-provider";
import { SignInModal } from "../Signin";
import logo from "./logo.png";
import logowhite from "./logo_white.png";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { MobileMenuDropdown } from "../MobileMenuDropdown";
import { UserMenuDropdown } from "../UserMenuDropdown";

const useStyles = makeStyles((theme: any) => ({
  navButtonDark: {
    color: theme.palette.grey.A400,
    fontWeight: "bold",
  },
  navButtonLight: {
    color: "white",
    fontWeight: "bold",
  },
  signinButton: {},
}));

interface NavbarProps {
  mode: "light" | "dark";
  width: number;
  hideSignIn?: boolean;
  style?: React.CSSProperties;
}

export const NavBar: React.FC<NavbarProps> = ({
  mode,
  width,
  hideSignIn,
  style,
}) => {
  const { showModal } = useModal();
  const history = useHistory();
  const classes = useStyles();

  return (
    <Box sx={{ marginLeft: "auto", marginTop: 0 }}>
      <AppBar
        position="static"
        style={{
          boxShadow: "none",
          width: "100%",
          paddingTop: 40,
          paddingBottom: 40,
          background: "none",
          marginTop: 0,
          ...style,
        }}
      >
        <Toolbar
          style={{
            width: "80vw",
            margin: "auto",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {width < 850 && <MobileMenuDropdown mode={mode} />}
          <div style={{ display: "flex", alignItems: "end" }}>
            <Link to="/">
              <img
                src={mode === "light" ? logowhite : logo}
                alt="CF Logo"
                style={{ width: 120 }}
              />
            </Link>
            <div>
              <div
                style={{
                  padding: "4px 6px",
                  backgroundColor: "rgb(255 255 255 / 33%)",
                  color: mode === "light" ? "white" : "#AEBACD",
                  letterSpacing: 2,
                  fontSize: 12,
                }}
              >
                ALPHA
              </div>
            </div>
          </div>
          {width > 850 && (
            <div
              className={
                mode === "light"
                  ? classes.navButtonLight
                  : classes.navButtonDark
              }
              style={{ display: "flex", flexDirection: "row", gap: 30 }}
            >
              <Button
                color="inherit"
                onClick={() => {
                  history.push("/");
                }}
              >
                Home
              </Button>

              <Button
                color="inherit"
                onClick={() => {
                  history.push("/about");
                }}
              >
                About ICE
              </Button>
              <Button
                color="inherit"
                target="_blank"
                href="https://www.independent.co.uk/news/health/nhs-patients-care-postcode-lottery-b1929279.html"
              >
                In the news
              </Button>
              <Button
                color="inherit"
                target="_blank"
                href="https://www.carnallfarrar.com/contact-us/"
              >
                Get in touch
              </Button>
            </div>
          )}
          <div
            className={
              mode === "light" ? classes.navButtonLight : classes.navButtonDark
            }
          >
            {hideSignIn ? null : width < 850 ? (
              <UserMenuDropdown mode={mode} />
            ) : (
              <Button
                color="inherit"
                variant="outlined"
                onClick={() => {
                  const token = localStorage.getItem("token");
                  if (token) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("idToken");
                    window.location.reload();
                  } else {
                    showModal(SignInModal, {
                      title: "signin",
                      signIn: "login",
                    });
                  }
                }}
              >
                {localStorage.getItem("token") ? "Sign out" : "Sign in"}
              </Button>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
