import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { FormInput } from "./FormInput";
import { makeStyles } from "@mui/styles";
// Shape of form values
interface FormValues {
  email: string;
}

interface OtherProps {
  message: string;
  setLoginState: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme: any) => ({
  form: {
    padding: "25px",
    gap: "25px",
    "& > .MuiBox-root > div": {
      maxWidth: "unset",
      minHeight: "65px",
      margin: "14px 0 0",
      "& > input": {
        width: "unset",
        "& + div": {
          marginLeft: "4px",
        },
      },
    },
  },
  error: {
    fontSize: 14,
    color: "red",
  },
  button: {
    "&.MuiButton-root": {
      backgroundColor: "#26A5B8",
      color: "white",
      fontWeight: "bold" as "bold",
      height: "40px",
      margin: "14px 0",
      textTransform: "none",
      "&.Mui-disabled[disabled]": {
        color: "white",
      },
    },
  },
  input: {
    backgroundColor: "#A5E9F2",
  },
  header: {
    textAlign: "left" as "left",
    fontWeight: "normal",
  },
  bottomRow: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
  },
  link: {
    color: "#26A5B8",
    cursor: "pointer",
    textDecoration: "none",
  },
}));

// Aside: You may see InjectedFormikProps<OtherProps, FormValues> instead of what comes below in older code.. InjectedFormikProps was artifact of when Formik only exported a HoC. It is also less flexible as it MUST wrap all props (it passes them through).
const InnerForm = (props: OtherProps) => {
  const classNames = useStyles();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: LoginValidation,

    onSubmit: (values: FormValues) => {
      return fetch(`${process.env.REACT_APP_API_URL!}/change_password`, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then(() => {
          window.location.reload();
        });
    },
  });

  const { message } = props;

  const disabled =
    Boolean(Object.keys(formik.errors).length) || !Boolean(formik.values.email);

  return (
    <form onSubmit={formik.handleSubmit} className={classNames.form}>
      <h2 className={classNames.header}>{message}</h2>
      <Box>
        <FormInput
          title="email"
          type="text"
          placeholder="Email address"
          value={formik.values.email}
          error={formik.touched.email ? formik.errors.email : ""}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          required
        />
      </Box>
      <Button
        className={classNames.button}
        variant="contained"
        fullWidth
        type="submit"
        disabled={disabled}
      >
        Submit
      </Button>
      <div className={classNames.bottomRow}>
        <span
          className={classNames.link}
          onClick={() => props.setLoginState("login")}
        >
          Back to Sign In
        </span>
      </div>
    </form>
  );
};

const LoginValidation = Yup.object().shape({
  email: Yup.string().email().required(),
});

// Wrap our form with the withFormik HoC
export const ForgotPassword = InnerForm;
