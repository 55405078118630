import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { keys, ceil, orderBy, indexOf } from "lodash";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import { makeStyles } from "@mui/styles";
import { Tooltip } from "@mui/material";
import { Dictionary, ExtendedMetrics, Metric } from "../http/metrics";
import { chasmColorMap, Chasms } from "./ChasmSelection";
import { CCGExtendedMetrics, CCGMetric } from "../http/ccg-metrics";
import { isCCGMetric, isCCGRawMetric } from "../http/metrics-helper";
import { Option } from "./OptionSelection";
import { median } from "../helper";

const useStyles = makeStyles(() => ({
  // metricTitle: {
  //   color: "blue",
  //   borderBottom: "1pt dashed white",
  // },
  stpTitle: {
    borderBottom: "1pt dashed white",
  },
  rootLabel: {
    margin: "0px !important",
  },
}));

interface colProps {
  id: string;
  label: string;
  align: string;
}

interface TableProps {
  rows: (Metric | CCGMetric)[];
  raw_metrics: (ExtendedMetrics | CCGExtendedMetrics)[];
  mode: Option;
  lookup: Dictionary[];
  selectedChasm: Chasms;
  selectedRegion?: string;
}

const defaultICS = "Bath and North East Somerset, Swindon and Wiltshire";
const defaultCCG = "NHS Halton CCG";

type ColOptions = {
  [key: string]: string;
};

const colLookup: ColOptions = {
  metricName: "Metric Name",
  chasm: "Chasm",
  selectedStpScore: "Selected ICS index",
  rank: "Rank",
  absoluteValue: "Absolute",
  medianValue: "Median",
};

const getTableRow = (
  metric: Dictionary,
  rows: (Metric | CCGMetric)[],
  selectedRegion: string,
  raw_metrics: (ExtendedMetrics | CCGExtendedMetrics)[]
) => {
  const selectedRegionObj = rows.find((row) => {
    return isCCGMetric(row)
      ? row.ccg_name === selectedRegion
      : row.ics_name === selectedRegion;
  });

  const stpScore = selectedRegionObj
    ? ceil((selectedRegionObj as any)[metric.metric_id], 2)
    : "No data";

  const metricFilter = orderBy(
    rows.map((row) => {
      return {
        regionName: isCCGMetric(row) ? row.ccg_name : row.ics_name,
        metric: (row as any)[metric.metric_id],
      };
    }),
    ["metric"],
    ["desc"]
  );

  const regionRank = indexOf(
    metricFilter,
    metricFilter.find((row) => {
      return row.regionName === selectedRegion;
    })
  );

  const selectedRawMetric = raw_metrics.find((rawMetric) => {
    const id = isCCGRawMetric(rawMetric)
      ? rawMetric.ccg_code
      : rawMetric.ics_name;

    return rawMetric.metric_id === metric.metric_id && id === selectedRegion;
  });

  const medianMetric = raw_metrics
    .filter((rawMetric) => {
      return rawMetric.metric_id === metric.metric_id;
    })
    .map((rawMetric) => rawMetric.value)
    .sort();

  let medianValue;
  try {
    medianValue = ceil(median(medianMetric), 2);
  } catch (e) {
    medianValue = "None";
  }

  return {
    metricName: metric.metric_fe_name,
    chasm: metric.chasm,
    selectedStpScore: stpScore,
    rank: regionRank + 1,
    absoluteValue: selectedRawMetric
      ? ceil(selectedRawMetric.value, 2)
      : "None",
    medianValue,
  };
};

const descendingComparator = (a: any, b: any, orderBy: string) => {
  if (typeof a[orderBy] !== "number") return 1;
  if (typeof b[orderBy] !== "number") return -1;
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order: Order, orderBy: string) => {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

type Order = "asc" | "desc" | undefined;

const chasmLookup = {
  physical_and_mental_health: "Physical and Mental Health",
  community_and_hospital_care: "Community and Hospital Care",
  health_and_social_care: "Health and Social Care",
};

export const MainTable: React.FC<TableProps> = ({
  rows,
  lookup,
  mode,
  selectedChasm,
  selectedRegion,
  raw_metrics,
}) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("selectedStpScore");

  const ensureSelectedRegion =
    selectedRegion || (mode.value === "ics" ? defaultICS : defaultCCG);

  const handleRequestSort = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler =
    (property: string) =>
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      handleRequestSort(event, property);
    };

  const dataProcessing = lookup.map((metricDict) => {
    return getTableRow(metricDict, rows, ensureSelectedRegion, raw_metrics);
  });

  const chasmFilter = dataProcessing.filter((row) => {
    if (selectedChasm !== "overall_index") {
      return row.chasm === chasmLookup[selectedChasm];
    }

    return row;
  });

  colLookup.selectedStpScore = ensureSelectedRegion;

  const columns = keys(colLookup).map((col) => {
    const label = colLookup[col];
    const column: colProps = {
      id: col,
      label,
      align: "right",
    };
    return column;
  });

  return (
    <TableContainer style={{ maxHeight: "50vh" }}>
      <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
        style={{
          borderCollapse: "separate",
          borderSpacing: "0px 4px",
        }}
      >
        <TableHead
          style={{
            height: "50px",
            borderBottom: "1pt solid #0E5A8A",
          }}
        >
          <TableRow>
            {columns.map((column, index) => {
              return (
                <TableCell
                  key={index}
                  align="left"
                  style={{
                    backgroundColor: chasmColorMap[selectedChasm].color,
                    color: "white",
                    fontWeight: "bold",
                    textAlign:
                      column.id !== "metricName" && column.id !== "chasm"
                        ? "center"
                        : "left",
                    borderRight: "1px solid white",
                    margin: 0,
                    borderTopLeftRadius: index === 0 ? 6 : 0,
                    borderTopRightRadius: index === columns.length - 1 ? 6 : 0,
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    onClick={createSortHandler(column.id)}
                    direction={orderBy === column.id ? order : "asc"}
                    style={{ fontSize: 14, color: "white" }}
                    classes={{ root: classes.rootLabel }}
                  >
                    {index === 2 ? (
                      <Tooltip title={<h3>{column.label}</h3>} arrow>
                        <span className={classes.stpTitle}>
                          {mode.value === "ics" ? "ICS score" : "CCG score"}
                        </span>
                      </Tooltip>
                    ) : (
                      column.label
                    )}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody style={{ width: "200px" }}>
          {chasmFilter
            .sort(getComparator(order, orderBy))
            .map((row, index: number) => {
              return (
                <TableRow
                  style={{
                    backgroundColor: "#E9EEFA",
                  }}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                >
                  {columns.map((column) => {
                    const value = (row as any)[column.id];

                    if (column.id === "metricName") {
                      const metricDict = lookup.find(
                        (l) => l.metric_fe_name === value
                      );

                      if (!metricDict) {
                        return null;
                      }

                      return (
                        <TableCell
                          key={column.id}
                          align="left"
                          style={{
                            fontSize: 12,
                            paddingTop: 14,
                            paddingBottom: 14,
                          }}
                        >
                          <Tooltip
                            title={<h3>{metricDict.description}</h3>}
                            arrow
                          >
                            <span
                              style={{
                                color: "#44546A",
                              }}
                            >
                              {metricDict.metric_fe_name}
                            </span>
                          </Tooltip>
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell
                        key={column.id}
                        align="left"
                        style={{
                          fontSize: 12,
                          textAlign: column.id !== "chasm" ? "center" : "left",
                          color: "#44546A",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <span
                          style={{
                            marginRight: column.id !== "chasm" ? 20 : 0,
                          }}
                        >
                          {value}
                        </span>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
