import { createTheme } from "@mui/material/styles";

const themeOptions = {
  palette: {
    primary: {
      50: "#E0F6F9",
      100: "#B2E8EF",
      200: "#82DAE6",
      300: "#54CADB",
      400: "#37BFD2",
      500: "#29B4CB",
      600: "#26A5B8",
      700: "#22909F",
      800: "#1F7C87",
      900: "#19595D",
      A100: "#B2E8EF",
      A200: "#82DAE6",
      A400: "#37BFD2",
      A700: "#22909F",
      main: "#29B4CB",
    },
    secondary: {
      50: "#FBE4F0",
      100: "#F6BCD9",
      200: "#F28FC0",
      300: "#F061A6",
      400: "#EF3B90",
      500: "#EF007A",
      600: "#DD0076",
      700: "#C6036F",
      800: "#B0046A",
      900: "#8A0460",
      A100: "#F6BCD9",
      A200: "#F28FC0",
      A400: "#EF3B90",
      A700: "#C6036F",
      main: "#EF007A",
    },
    grey: {
      50: "#E9EEFA",
      100: "#CBD6E5",
      200: "#AEBACD",
      300: "#8F9EB5",
      400: "#7989A3",
      500: "#627592",
      600: "#546781",
      700: "#44546A",
      800: "#354154",
      900: "#222D3C",
      A100: "#CBD6E5",
      A200: "#AEBACD",
      A400: "#7989A3",
      A700: "#44546A",
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: 14,
          margin: "0px 12px",
          fontWeight: 500,
        },
      },
    },
  },
};

export const theme = createTheme({
  ...themeOptions,
});
