/* eslint import/first: off */
import mapboxgl from "mapbox-gl";
import { useEffect, useState } from "react";
import { Charts } from "../components/Charts";
import { MainTable } from "../components/Table";
import { NavBar } from "../components/navbar/NavBar";
import { Map } from "../components/Map";
import { Info } from "../components/Info";
import { TabBar } from "../components/TabBar";
import { Chasms, ChasmSelection } from "../components/ChasmSelection";
import { useWindowSize } from "../hooks/window";
import {
  getMetrics,
  Metric,
  Dictionary,
  ExtendedMetrics,
} from "../http/metrics";
import { CCGExtendedMetrics, CCGMetric } from "../http/ccg-metrics";
import { isCCGMetric } from "../http/metrics-helper";
import { Option } from "../components/OptionSelection";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { SelectionRequired } from "../components/SelectionRequired";
import {
  CCGFeature,
  getFilteredMetrics,
  ICSFeature,
  isCCGProperties,
} from "../selectors/comparison";

interface MetricsPayload {
  dictionary: Dictionary[];
  metrics: (Metric | CCGMetric)[];
  extended_metrics: (ExtendedMetrics | CCGExtendedMetrics)[];
}

export const MainPage = () => {
  const [payload, setPayload] = useState<MetricsPayload>();
  const [mapRef, setMap] = useState<mapboxgl.Map | undefined>();
  const [selectedFeature, setSelectedFeature] = useState<
    ICSFeature | CCGFeature
  >();
  const [customFeatures, setCustomFeatures] = useState<
    (ICSFeature | CCGFeature)[]
  >([]);
  const [chasm, setChasm] = useState<Chasms>("overall_index");
  const [view, setView] = useState("Chart");
  const [mode, setMode] = useState<Option>({ label: <>ICS</>, value: "ics" });
  const [comparison, setComparison] = useState<Option>({
    label: <ArrowRightAltIcon />,
    value: "nocomparison",
    info: "No Compare",
  });
  const [width] = useWindowSize();
  const small = width < 1250;

  useEffect(() => {
    getMetrics(mode.value).then((res) => setPayload(res));
  }, [mode]);

  useEffect(() => {
    if (comparison.value === "customcomparison" && selectedFeature) {
      const includeSelected = customFeatures.some((feature) => {
        if (
          isCCGProperties(feature.properties) &&
          isCCGProperties(selectedFeature.properties)
        ) {
          return (
            feature.properties.CCG21NM === selectedFeature.properties.CCG21NM
          );
        }

        if (
          !isCCGProperties(feature.properties) &&
          !isCCGProperties(selectedFeature.properties)
        ) {
          return (
            feature.properties.ics_name === selectedFeature.properties.ics_name
          );
        }

        return false;
      });

      if (!includeSelected) {
        setCustomFeatures([...customFeatures, selectedFeature]);
      }
    }

    if (customFeatures.length && comparison.value !== "customcomparison") {
      setCustomFeatures([]);
    }
  }, [selectedFeature, comparison, setCustomFeatures, customFeatures]);

  let selectedValue: number | undefined;
  let regionName: string | undefined;
  let regionExtendedName: string | undefined;
  let filteredMetrics: (Metric | CCGMetric)[] | undefined;

  if (selectedFeature && payload && payload.metrics) {
    const selectedRegion = payload.metrics.find((val) => {
      if (isCCGMetric(val) && isCCGProperties(selectedFeature.properties)) {
        return val.ccg_name === selectedFeature.properties.CCG21NM;
      }

      if (!isCCGMetric(val) && !isCCGProperties(selectedFeature.properties)) {
        return val.ics_name === selectedFeature.properties.ics_name;
      }

      return false;
    });

    if (selectedRegion) {
      selectedValue = (selectedRegion as any)[chasm];
      if (isCCGMetric(selectedRegion)) {
        regionName = selectedRegion?.ccg_name;
        regionExtendedName = `${regionName} (ICS: ${selectedRegion?.ics_name})`;
      } else {
        regionName = selectedRegion?.ics_name;
        regionExtendedName = `${regionName} (Region: ${selectedRegion?.region_name})`;
      }

      if (comparison.value !== "nocomparison") {
        filteredMetrics = getFilteredMetrics(
          payload.metrics,
          selectedRegion,
          comparison,
          chasm,
          customFeatures
        );
      }
    }
  }

  const selectionRequired =
    comparison.value !== "nocomparison" && !selectedValue;

  const metricsData = filteredMetrics
    ? filteredMetrics
    : payload?.metrics ?? [];

  return (
    <div style={{ marginTop: -50 }}>
      <NavBar mode="dark" width={width} style={{ paddingTop: 100 }} />
      <div
        style={{
          maxWidth: "80vw",
          margin: "auto",
          boxSizing: "border-box",
        }}
      >
        <div style={{ display: "flex", marginTop: 10 }}>
          <div style={{ flex: 1, maxWidth: 700 }}>
            <Info
              chasm={chasm}
              value={selectedValue}
              regionExtendedName={regionExtendedName}
              mode={mode}
              comparison={comparison}
              selectionRequired={selectionRequired}
              onUnselect={() => {
                setSelectedFeature(undefined);
                setCustomFeatures([]);
              }}
            />
            <TabBar onChange={setView} value={view} small={small} />
            <ChasmSelection
              onSelect={(chasm) => {
                setChasm(chasm as Chasms);
              }}
            />
            {view === "Chart" && !selectionRequired && (
              <Charts
                chasm={chasm}
                data={metricsData}
                selectedFeature={selectedFeature}
                comparison={comparison}
              />
            )}
            {view === "Chart" && selectionRequired && <SelectionRequired />}
            {view === "Table" && (
              <MainTable
                rows={payload?.metrics ?? []}
                raw_metrics={payload?.extended_metrics ?? []}
                mode={mode}
                lookup={payload?.dictionary ?? []}
                selectedChasm={chasm}
                selectedRegion={regionName}
              />
            )}
            {view === "Map" && (
              <Map
                onChange={(mode) => setMode(mode)}
                comparison={comparison}
                setComparison={setComparison}
                mode={mode}
                chasm={chasm}
                selectedFeature={selectedFeature}
                onClickFeature={setSelectedFeature}
                setMap={setMap}
                mapRef={mapRef}
                filteredData={filteredMetrics}
                data={payload?.metrics ?? []}
              />
            )}
          </div>
          {!small && (
            <Map
              onChange={(mode) => setMode(mode)}
              comparison={comparison}
              setComparison={setComparison}
              mode={mode}
              chasm={chasm}
              selectedFeature={selectedFeature}
              onClickFeature={setSelectedFeature}
              setMap={setMap}
              mapRef={mapRef}
              filteredData={filteredMetrics}
              data={payload?.metrics ?? []}
            />
          )}
        </div>
      </div>
    </div>
  );
};
