import { CCGExtendedMetrics, CCGMetric } from "./ccg-metrics";
import { ExtendedMetrics, Metric } from "./metrics";

export const isCCGMetric = (
  metric: Metric | CCGMetric
): metric is CCGMetric => {
  return !!(metric as any).ccg_name;
};

export const isCCGRawMetric = (
  rawMetric: ExtendedMetrics | CCGExtendedMetrics
): rawMetric is CCGExtendedMetrics => {
  return !!(rawMetric as any).ccg_code;
};
