import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  chevron: {
    "&::before": {
      borderStyle: "solid",
      borderWidth: "0.15em 0.15em 0 0",
      content: "''",
      display: "inline-block",
      width: "0.45em",
      height: "0.45em",
      left: "0.15em",
      position: "relative",
      top: "0.55em",
      transform: "rotate(-45deg)",
      verticalAlign: "top",
    },
    "&.chevron-right:before": {
      left: 0,
      transform: "rotate(45deg)",
    },
    "&.chevron-bottom:before": {
      transform: "rotate(135deg)",
    },
    "&.chevron-left:before": {
      left: "0.25em",
      transform: "rotate(-135deg)",
    },
  },
}));

interface ChevronProps {
  variant: "top" | "right" | "bottom" | "left";
  className?: string;
}

const Chevron: React.FC<ChevronProps> = ({
  variant = "top",
  className = "",
  ...props
}) => {
  const classes = useStyles();
  return (
    <span
      {...props}
      className={[classes.chevron, `chevron-${variant}`, className].join(" ")}
    ></span>
  );
};

export default Chevron;
