import { DefaultTheme } from "styled-components";
import { Theme } from "@carnallfarrar/fitzroy";

const ChartTheme: DefaultTheme = {
  ...Theme.CFConfig,
  variantColors: {
    ...Theme.CFConfig.variantColors,
    primary: {
      ...Theme.CFConfig.variantColors.primary,
      text: "#EF007A",
    },
    secondary: {
      ...Theme.CFConfig.variantColors.secondary,
      text: "#FABF27",
    },
    tertiary: {
      ...Theme.CFConfig.variantColors.tertiary,
      text: "#49AE70",
    },
    quaternary: {
      ...Theme.CFConfig.variantColors.quaternary,
      text: "#973AA4",
    },
  },
};

export default ChartTheme;
