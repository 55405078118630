import * as React from "react";
import { ThemeProvider } from "styled-components";
import { Charts, Theme } from "@carnallfarrar/fitzroy";
import ChartTheme from "../config/ChartTheme";
import {
  CCGFeature,
  ICSFeature,
  isCCGProperties,
} from "../selectors/comparison";
import { ChartTooltip } from "./ChartTooltip";

export interface Props {
  data?: {
    name: string;
    value: number;
  }[];

  selectedFeature?: ICSFeature | CCGFeature;
}

const { VerticalBarChart } = Charts;

export const Chart: React.FC<Props & Theme.VariantProps> = ({
  data,
  variant,
  selectedFeature,
}) => {
  if (!data) {
    return null;
  }
  let selectedRegionName: string | undefined;

  if (selectedFeature) {
    selectedRegionName = isCCGProperties(selectedFeature.properties)
      ? selectedFeature.properties.CCG21NM
      : selectedFeature.properties.ics_name;
  }
  return (
    <div style={{ position: "relative" }}>
      <ThemeProvider theme={ChartTheme}>
        <VerticalBarChart
          variant={variant}
          width={550}
          height={200}
          tooltip={(args) => ChartTooltip({ ...args, variant })}
          data={data.map((point) => ({
            value: point.value,
            label: point.name,
          }))}
          yLabel="Index value"
          showXTicks={false}
          selectedLabel={selectedRegionName}
        />
      </ThemeProvider>
    </div>
  );
};
