import { max, min } from "lodash";
import ics from "../ics-full.json";
import ccgs from "../ccg_2021_geo.json";
import mapboxgl, { LngLat } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MapboxMap, GeoJSON, PopUp } from "@carnallfarrar/react-mapbox-light";
import { Chasms } from "./ChasmSelection";
import { Metric } from "../http/metrics";
import { CCGMetric } from "../http/ccg-metrics";
import { isCCGMetric } from "../http/metrics-helper";
import { Option, OptionSelection } from "./OptionSelection";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import {
  CCGFeature,
  ICSFeature,
  isCCGProperties,
} from "../selectors/comparison";
import { useState } from "react";
const MapboxWorker = require("mapbox-gl/dist/mapbox-gl-csp-worker").default;

(mapboxgl as any).workerClass = MapboxWorker; // Wire up loaded worker to be used instead of the default

interface MapProps {
  chasm: Chasms;
  mode: Option;
  selectedFeature?: ICSFeature | CCGFeature;
  onClickFeature: (feature: ICSFeature | CCGFeature) => void;
  setMap: any;
  mapRef: any;
  data: (Metric | CCGMetric)[];
  filteredData: (Metric | CCGMetric)[] | undefined;
  onChange: (option: Option) => void;
  comparison: Option;
  setComparison: (option: Option) => void;
}

const chasmColors: { [key: string]: { high: string; low: string } } = {
  overall_index: { high: "#8A0460", low: "#F6BCD9" },
  physical_and_mental_health: { high: "#F5711A", low: "#FDEBB5" },
  community_and_hospital_care: { high: "#295C3B", low: "#C7E6D2" },
  health_and_social_care: { high: "#4D227A", low: "#DFC0E3" },
};

const combineAPIDataWithRegionGEOJSON = (
  apiData: (Metric | CCGMetric)[],
  geoJson: any,
  chasm: Chasms,
  selectedName?: string,
  exclude?: (Metric | CCGMetric)[]
) => {
  const excludeNames =
    exclude?.map((metric) =>
      isCCGMetric(metric) ? metric.ccg_name : metric.ics_name
    ) ?? [];

  return {
    ...geoJson,
    features: geoJson.features
      .map((feature: any) => {
        const value = apiData.find((region) => {
          if (isCCGMetric(region)) {
            return (
              region.ccg_name === feature.properties.CCG21NM &&
              !excludeNames.includes(region.ccg_name)
            );
          } else {
            return (
              region.ics_name === feature.properties.ics_name &&
              !excludeNames.includes(region.ics_name)
            );
          }
        });

        if (value) {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              val: value[chasm],
            },
          };
        } else {
          return undefined;
        }
      })
      .filter((f: any) => {
        if (selectedName && !!f) {
          return (
            (f.properties.ics_name || f.properties.CCG21NM) === selectedName
          );
        }

        return !!f;
      }),
  };
};

interface HoverProperty {
  feature?: ICSFeature | CCGFeature;
  latlng?: LngLat;
}

export const Map = ({
  chasm,
  selectedFeature,
  onClickFeature,
  data,
  filteredData,
  onChange,
  mode,
  comparison,
  setComparison,
}: MapProps) => {
  const [hoverFeature, setHoverFeature] = useState<HoverProperty>({});

  let geojson;
  let filteredGeoJSON;

  let minData = 0;
  let maxData = 1;

  if (data) {
    const rawgeojson = mode.value === "ics" ? ics : ccgs;
    geojson = combineAPIDataWithRegionGEOJSON(
      data,
      rawgeojson,
      chasm,
      undefined,
      filteredData
    );

    if (filteredData) {
      filteredGeoJSON = combineAPIDataWithRegionGEOJSON(
        filteredData,
        rawgeojson,
        chasm
      );
    }

    maxData = max(geojson.features.map((row: any) => row.properties.val)) ?? 1;
    minData = min(geojson.features.map((row: any) => row.properties.val)) ?? 0;
  }

  let selectedSource: any;

  if (selectedFeature) {
    selectedSource = {
      type: "FeatureCollection",
      features: geojson.features.filter((feat: any) => {
        if (selectedFeature) {
          if (isCCGProperties(selectedFeature.properties)) {
            return (
              selectedFeature.properties.CCG21CD === feat.properties.CCG21CD
            );
          } else {
            return feat.properties.id === selectedFeature.properties.id;
          }
        } else {
          return false;
        }
      }),
    };
  }

  const mainFillColor = filteredData
    ? "#CBD6E5"
    : {
        property: "val",
        stops: [
          [minData, chasmColors[chasm].low],
          [maxData, chasmColors[chasm].high],
        ],
      };

  return (
    <div style={{ flex: 1, position: "relative", height: "75vh" }}>
      <OptionSelection
        options={[
          {
            label: <ArrowRightAltIcon />,
            value: "nocomparison",
            info: "No compare",
          },
          {
            label: <CompareArrowsIcon />,
            value: "similarcomparison",
            info: "Compare similar by IMD",
          },
          {
            label: <LocationSearchingIcon />,
            value: "closecomparison",
            info: `Compare within ${mode.value === "ics" ? "region" : "ICS"}`,
          },
          {
            label: <DashboardCustomizeIcon />,
            value: "customcomparison",
            info: "Custom compare",
          },
        ]}
        position="left"
        selected={comparison}
        onChange={(val) => setComparison(val)}
      />
      <OptionSelection
        options={[
          { label: <>ICS</>, value: "ics" },
          { label: <>CCG</>, value: "ccg" },
        ]}
        selected={mode}
        onChange={(val) => onChange(val)}
      />
      <MapboxMap
        token={process.env.REACT_APP_MAPBOX_TOKEN!}
        scrollZoom={false}
        mapboxOptions={{
          style: { version: 8, sources: {}, layers: [] },
          center: [-2.3783131, 52.9400067],
          zoom: 5.3,
          dragPan: false,
        }}
      >
        {filteredGeoJSON && (
          <GeoJSON
            data={filteredGeoJSON}
            onClick={(e) => {
              e.preventDefault();
              const features = e.features as any;
              if (features && features.length) {
                const selectedFeature = features[0];
                onClickFeature(selectedFeature);
              }
            }}
            onMouseMove={(e, features) => {
              const feature = features?.find(
                (row) => row.layer.id === "regionCompareFillIn"
              );
              setHoverFeature(
                feature
                  ? {
                      feature: feature as any,
                      latlng: e.lngLat,
                    }
                  : {}
              );
            }}
            onMouseLeave={(e) => setHoverFeature({})}
            id="regionCompare"
            layers={[
              {
                id: "regionCompareFillIn",
                type: "fill",
                source: "regionCompare",
                layout: {},
                paint: {
                  "fill-color": {
                    property: "val",
                    stops: [
                      [minData, chasmColors[chasm].low],
                      [maxData, chasmColors[chasm].high],
                    ],
                  },
                  "fill-opacity": 1,
                },
              },
              {
                id: "regionCompareOutline",
                type: "line",
                source: "regionCompare",
                layout: {},
                paint: {
                  "line-color": "#000",
                  "line-width": 1,
                },
              },
            ]}
          />
        )}
        {geojson && (
          <GeoJSON
            data={geojson}
            onMouseMove={(e, features) => {
              const feature = features?.find(
                (row) => row.layer.id === "fillin"
              );

              setHoverFeature(
                feature
                  ? {
                      feature: feature as any,
                      latlng: e.lngLat,
                    }
                  : {}
              );
            }}
            onMouseLeave={(e) => setHoverFeature({})}
            onClick={(e) => {
              e.preventDefault();
              const features = e.features as any;
              if (features && features.length) {
                const selectedFeature = features[0];
                onClickFeature(selectedFeature);
              }
            }}
            id="region"
            layers={[
              {
                id: "fillin",
                type: "fill",
                source: "region",
                layout: {},
                paint: {
                  "fill-color": mainFillColor,
                  "fill-opacity": 1,
                },
              },
              {
                id: "outline",
                type: "line",
                source: "region",
                layout: {},
                paint: {
                  "line-color": "#000",
                  "line-width": 1,
                },
              },
            ]}
          />
        )}
        {hoverFeature.latlng && hoverFeature.feature && (
          <PopUp
            lnglat={hoverFeature.latlng}
            closeButton={false}
            closeOnClick={false}
          >
            <div>
              {isCCGProperties(hoverFeature.feature.properties)
                ? hoverFeature.feature.properties.CCG21NM
                : hoverFeature.feature.properties.ics_name}
            </div>
          </PopUp>
        )}
        {selectedSource && (
          <GeoJSON
            data={selectedSource}
            id="selectedRegion"
            layers={[
              {
                id: "outline-selected",
                type: "line",
                source: "selectedRegion",
                layout: {},
                paint: {
                  "line-color": "#fff",
                  "line-width": 2,
                },
              },
            ]}
          />
        )}
      </MapboxMap>
    </div>
  );
};
