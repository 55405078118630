import React from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import TableChartIcon from "@mui/icons-material/TableChart";
import MapIcon from "@mui/icons-material/Map";
import { Radio } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  labelContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12,
    paddingLeft: 12,
  },
}));

interface TabBarLabelProps {
  Icon: any;
  label: string;
  selected: boolean;
  disabled: boolean;
}

const TabBarLabel: React.FC<TabBarLabelProps> = ({
  Icon,
  label,
  selected,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.labelContainer}
      style={{
        color: disabled ? "#AEBACD" : selected ? "#29B4CB" : "#627592",
      }}
    >
      <Icon color="inherit" size={22} />
      <div style={{ marginLeft: 12, fontSize: 22 }}>{label}</div>
    </div>
  );
};

const RadioButton: React.FC<any> = ({
  onChange,
  value,
  label,
  small,
  disabled,
}) => {
  let icon = TableChartIcon;

  if (label === "Map") {
    icon = MapIcon;
  }
  if (label === "Chart") {
    icon = BarChartIcon;
  }

  let style: React.CSSProperties = {};

  if (disabled) {
    style["color"] = "#AEBACD";
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexBasis: "45%",
        flexShrink: "auto",
        borderRadius: 5,
        padding: small ? 10 : "10px 20px",
        border: disabled
          ? "1px solid #AEBACD"
          : !!value
          ? "1px solid #29B4CB"
          : "1px solid #627592",
        cursor: disabled ? "inherit" : "pointer",
      }}
      onClick={() => {
        if (!disabled) {
          onChange(label);
        }
      }}
    >
      <TabBarLabel
        Icon={icon}
        label={label}
        selected={value}
        disabled={disabled}
      />
      <Radio
        style={style}
        disabled={!!disabled}
        disableRipple
        onChange={() => onChange(label)}
        value={label}
        checked={value}
      />
    </div>
  );
};

interface TabBarProps {
  value: string;
  onChange: (view: string) => void;
  small: boolean;
}

export const TabBar: React.FC<TabBarProps> = ({ onChange, value, small }) => {
  const accessToken = localStorage.getItem("token");

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "space-between", gap: 10 }}
      >
        <RadioButton
          small={small}
          onChange={() => onChange("Chart")}
          value={value === "Chart"}
          label="Chart"
        />
        {small && (
          <RadioButton
            small={small}
            onChange={() => onChange("Map")}
            value={value === "Map"}
            label="Map"
          />
        )}
        <RadioButton
          disabled={!accessToken}
          small={small}
          onChange={() => onChange("Table")}
          value={value === "Table"}
          label="Table"
        />
      </div>
    </div>
  );
};
