import { NavBar } from "../components/navbar/NavBar";
import Chevron from "./../components/Chevron";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import mobile from "../images/Group 94.png";
import mobileGiff from "../images/Giff01.gif";
import chartScreenshot from "../images/Chart Screenshot.png";
import mapScreenshot from "../images/Map Screenshot.png";
import chartIcon from "../images/Chart Icon.png";
import pathIcon from "../images/Path Icon.png";
import directionIcon from "../images/Direction Icon.png";
import { useWindowSize } from "../hooks/window";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  container: {
    position: "relative",
    overflow: "hidden",
    overflowY: "auto",
    height: "100vh",
    color: theme.palette.grey[500],
    fontWeight: 400,
    fontSize: "1.12rem",
    lineHeight: "1.7rem",
  },

  sectionItem: {
    display: "flex",
    gap: 30,
    width: "100vw",
    padding: "3vh 10vw",
    boxSizing: "border-box",
  },

  sectionBg: {
    backgroundColor: theme.palette.grey[50],
  },

  head3: {
    color: theme.palette.primary.main,
    fontSize: "3rem",
    lineHeight: "3.75rem",
    fontWeight: 700,
  },

  head4: {
    color: theme.palette.grey[500],
    fontSize: "1.15rem",
    lineHeight: "1.4rem",
    fontWeight: 600,
  },

  mobileImageBack: {
    position: "absolute",
    objectFit: "cover",
    height: "calc(100% - 40px)",
    left: "calc(50% - 155px)",
    top: "50px",
  },

  mobileImage: {
    position: "absolute",
    objectFit: "cover",
    height: "calc(100% - 220px)",
    left: "calc(50% - 110px)",
    top: "95px",
  },

  summary: {
    textAlign: "center",
  },

  cardItem: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: "1rem",
    margin: "1rem",
    borderRadius: "1rem",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
    "& h5": {
      fontSize: "1rem",
      lineHeight: "1.5rem",
      fontWeight: 600,
      marginBottom: 0,
    },
  },

  listPadded: {
    margin: 0,
    padding: "0 1.5rem",
    "& > li": {
      marginBottom: "2rem",
    },
  },

  listLevels: {
    "&,ul": {
      padding: "0 1.5rem",
    },
    "& > li": {
      color: theme.palette.primary.main,
      fontWeight: 600,
      fontSize: "1.25rem",
      lineHeight: "1.6rem",
      marginBottom: "2rem",
      listStyleType: "disc",
      "& > ul": {
        marginTop: "2rem",
        color: theme.palette.grey[500],
        fontWeight: 400,
        fontSize: "1.12rem",
        lineHeight: "1.7rem",
        "& > li": {
          listStyleType: "disc",
          "& ul > li": {
            listStyleType: "circle",
          },
        },
      },
    },
  },
  sectionButton: {
    "section &": {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.5rem",
      textTransform: "none",
      color: theme.palette.grey[300],
      "&:hover": {
        backgroundColor: theme.palette.grey[50],
      },
      "&.button-nav": {
        color: theme.palette.secondary[600],
        borderRadius: "0.5rem",
        borderColor: theme.palette.secondary[600],
        borderWidth: "0.13rem",
        "&:hover": {
          borderColor: theme.palette.secondary[600],
          backgroundColor: theme.palette.secondary[50],
          borderWidth: "0.13rem",
        },
        "& .chevron-right": {
          marginLeft: "1rem",
        },
      },
    },
  },
}));

export const AboutPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [width] = useWindowSize();
  const isMobile = width < 850;

  return (
    <div className={classes.container}>
      <div
        style={{
          background:
            "linear-gradient(342deg, rgba(2,0,36,1) 0%, rgba(72,34,101,1) 14%, rgba(118,56,144,1) 23%, rgba(172,81,194,1) 36%, rgba(185,87,206,1) 41%, rgba(201,95,221,1) 52%, rgba(198,100,223,1) 56%, rgba(144,200,255,1) 91%)",
          fontFamily: "sans-serif",
        }}
      >
        <NavBar mode="light" width={width} />
      </div>
      <section
        className={classes.sectionItem}
        style={{ flexDirection: isMobile ? "column" : "row" }}
      >
        <article style={{ flex: "1" }}>
          <h3 className={classes.head3}>
            How effective is your Integrated Care System?
          </h3>
          <h4 className={classes.head4}>
            Measuring The Actual Impact on Local Citizens and Determining Future
            Priorities
          </h4>
          <p>
            As ICSs develop their five-year strategies for genuine integration,
            wrapping seamless services and support around local people, the
            starting point must be an honest assessment of the current reality.
          </p>
          <p>
            Following conversations with ICS leaders on the need for data and
            intelligence on integration, we at CF in partnership with IPPR have
            developed the CF Integration Index.
          </p>
        </article>
        <div
          style={{
            flex: isMobile ? "none" : "1",
            position: "relative",
            height: "85vh",
          }}
        >
          <img
            src={mobile}
            alt="mobile background"
            className={classes.mobileImageBack}
          ></img>
          <img
            src={mobileGiff}
            alt="mobile giff"
            className={classes.mobileImage}
          ></img>
        </div>
      </section>
      <section
        className={[classes.sectionItem, classes.sectionBg].join(" ")}
        style={{ flexDirection: "column" }}
      >
        <h4 className={classes.head4}>
          The index is intended to help ICSs over the next five years to:
        </h4>
        <ul className={classes.listPadded}>
          <li>
            Identify the current state of integration across the whole locality
            and within the component places and neighbourhoods.
          </li>
          <li>
            Compare themselves to their near neighbours or ICSs of a similar
            demographic.
          </li>
          <li>
            Act as a tool for measuring progress towards better person-centred
            approaches across acute, community, primary and mental health care.
          </li>
        </ul>
      </section>

      <section
        className={classes.sectionItem}
        style={{ flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <img
            style={{ flex: 1, maxWidth: isMobile ? "80vw" : "40vw" }}
            src={chartScreenshot}
            alt="chart screenshot"
          ></img>
          <img
            style={{ flex: 1, maxWidth: isMobile ? "80vw" : "40vw" }}
            src={mapScreenshot}
            alt="map screenshot"
          ></img>
        </div>
        <p className={classes.summary}>
          The Insights and Collaboration Engine shows an overall ICS integration
          score alongside integration within column chart, with a score between
          -1 and 1, ranked against all other ICSs. Further insight is available
          through exploration of the integration scores of each metric within
          the chasms.
        </p>
      </section>

      <section
        className={classes.sectionItem}
        style={{ flexDirection: "column" }}
      >
        <h4 className={classes.head4}>
          Key findings from the tool show the variation in integration between
          ICSs and showcase the impact that improving system integration has on
          multiple aspects:
        </h4>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <article className={classes.cardItem}>
            <img src={chartIcon} alt="chart icon" width={45} height={40}></img>
            <h5>
              There is a significant variation in integration maturity between
              ICSs
            </h5>
            <p>
              For example, East London Health and Care Partnership has only a
              third of hospital admissions because of self-harm for
              10–24-year-olds compared to the national average, and a sixth
              compared to the worst performing ICS
            </p>
          </article>
          <article className={classes.cardItem}>
            <img src={pathIcon} alt="path icon" width={55} height={50}></img>
            <h5>
              If each ICS matched outcomes in the top 25 per cent of ICSs:
            </h5>
            <p>
              42,600 - bed days saved in hospitals due to fewer delayed
              discharges 63,300 - more patients with severe or complicated
              mental health illnesses having a care plan 9,100 - more patients
              getting cancer treatment within 2 months
            </p>
          </article>
          <article className={classes.cardItem}>
            <img
              src={directionIcon}
              alt="direction icon"
              width={47}
              height={53}
            ></img>
            <h5>
              ICSs that are well integrated between health and social care are
              strongly integrated between community and hospital care
            </h5>
            <p>
              ICSs which have strong integration between physical and mental
              care, on the other hand, do not always have strong integration
              between hospital and community care, or health and social care
            </p>
          </article>
        </div>
      </section>
      <section
        className={[classes.sectionItem, classes.sectionBg].join(" ")}
        style={{ flexDirection: "column" }}
      >
        <p className={classes.summary}>
          The tool will be further enhanced with additional features to provide
          more valuable insights to ICSs. These will include assessing
          integration at place level, comparing to nearest neighbours,
          customising selected metrics, the ability to construct network
          localities through combining different metrics, and the ability for
          ICSs to measure the effect of their strategy over time. More advanced
          functionality will require additional time and effort from development
          team and will therefore include a charge.
        </p>
      </section>
      <section
        className={classes.sectionItem}
        style={{ flexDirection: "column" }}
      >
        <h4 className={classes.head4}>
          CF are currently offering the tool at three different levels:
        </h4>
        <ul className={classes.listLevels}>
          <li>
            <span className="title">Level 1 – Free</span>
            <ul>
              <li>
                At level 1, we would provide access to the Insights and
                Collaboration Engine, including ICS integration score
              </li>
            </ul>
          </li>
          <li>
            <span className="title">
              Level 2 – Free, requires sign-up and on condition of providing
              user feedback
            </span>
            <ul>
              <li>
                At level 2, we would provide level 1 access plus
                <ul>
                  <li>ICS level Individual chasm and metric scores</li>
                  <li>CCG level integration scores</li>
                  <li>CCG level chasm breakdown</li>
                  <li>CCG level metric scores</li>
                  <li>
                    ICS and CCG level comparison feature including: comparison
                    with the similar scoring ICSs or CCGs; comparison with
                    neighbour ICSs or CCGs; comparison by custom selection;
                    comparison within the Region and ICS
                  </li>
                  <li>
                    Integration Index – Deprivation Index (IMD) Comparison
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <span className="title">
              Level 3 – An annual license fee is chargeable
            </span>
            <ul>
              <li>
                At level 3 we would provide full access to the Insights and
                Collaboration Engine, including
                <ul>
                  <li>ICS and Place level integration scores</li>
                  <li>
                    Nearest neighbour benchmarking (underlying similarity on
                    selected indexes and metrics)
                  </li>
                  <li>Customisable metrics</li>
                  <li>
                    Comparison in ICS and Place (CCG) level on selected metrics
                  </li>
                  <li>
                    Place and Neighbourhood breakdown – based on available data
                    or alternatively available local data
                  </li>
                  <li>Measure progress over time</li>
                  <li>Automatic detection</li>
                  <li>Downloadable reports</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <span className="title">Additional Services</span>
            <ul>
              <li>
                Consultancy Service to support strategising priorities and
                improving the integration
              </li>
            </ul>
          </li>
        </ul>
      </section>
      <section
        className={classes.sectionItem}
        style={{ justifyContent: "right", marginBottom: "2rem" }}
      >
        <Button
          target="_blank"
          href="https://www.carnallfarrar.com/contact-us/"
          className={classes.sectionButton}
        >
          Contact us
        </Button>
        <Button
          variant="outlined"
          onClick={() => history.push("/app")}
          className={[classes.sectionButton, "button-nav"].join(" ")}
        >
          Explore now
          <Chevron variant="right" />
        </Button>
      </section>
    </div>
  );
};
