import { Charts, Theme } from "@carnallfarrar/fitzroy";
import styled, { css } from "styled-components";

export const StyledTooltipContainer = styled.div<Theme.VariantProps>`
  border-radius: 0.3rem;
  margin: -0.4rem -0.5rem;
  padding: 0.5rem;
  ${(props) => {
    const color = Theme.variantTextColor(props);
    return css`
      color: ${color};
      border: 0.1rem solid ${color};
    `;
  }}
  & > h4 {
    margin: 0 0 0.5rem 0;
    padding: 0;
  }
`;

export function ChartTooltip(
  args: Charts.TooltipData & { variant?: Theme.ThemeVariant }
) {
  const { values, variant } = args;

  const [item] = values || [];
  const { label, value } = item || {};
  return (
    <StyledTooltipContainer variant={variant}>
      {label && <h4>{label}</h4>}
      {value && <span>Value: {value}</span>}
    </StyledTooltipContainer>
  );
}
