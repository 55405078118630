import { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { FormInput } from "./FormInput";
import { useHistory } from "react-router-dom";
// Shape of form values
interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  role: string;
  organisation: string;
}

const useStyles = makeStyles((theme: any) => ({
  formContainer: {
    display: "flex",
    flexDirection: "row",
    "@media screen and (max-width: 650px)": {
      flexDirection: "column",
    },
    "& > div > div": {
      maxHeight: "98px",
    },
    // width: "100%",
    // justifyContent: "space-around",
  },
  leftSide: {
    gridColumnStart: 1,
  },
  rightSide: {
    gridColumnStart: 2,
  },
  button: {
    width: "292px",
    height: "40px",
    color: "white",
    fontFamily: "Avant Garde Gothic - Book",
    fontWeight: "bold",
    backgroundColor: "#26A5B8",
    borderRadius: "8px",
    border: "none",
    cursor: "pointer",
    margin: "30px 20px 10px",
    "@media screen and (max-width: 1000px)": {
      width: "260px",
      marginLeft: "14px",
    },
    "&:hover": {
      backgroundColor: "#22909F",
    },
    "&:disabled": {
      backgroundColor: "#C4C4C4",
      cursor: "inherit",
      color: "#7B7B7B",
    },
  },
}));

// Aside: You may see InjectedFormikProps<OtherProps, FormValues> instead of what comes below in older code.. InjectedFormikProps was artifact of when Formik only exported a HoC. It is also less flexible as it MUST wrap all props (it passes them through).
const InnerForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const [signupSuccessful, setSignupSuccessful] = useState(false);
  const [signupError, setSignupError] = useState("");

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: "",
      organisation: "",
    },
    validationSchema: SignupValidation,

    onSubmit: (values: FormValues) => {
      return fetch(`${process.env.REACT_APP_API_URL!}/signup`, {
        method: "POST",
        body: JSON.stringify({
          email: values.email,
          password: values.password,
          firstname: values.firstName,
          lastname: values.lastName,
          role: values.role,
          organisation: values.organisation,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (!data.error) {
            setSignupSuccessful(true);
          } else {
            setSignupError(data.error);
          }
        });
    },
  });

  if (signupSuccessful) {
    return (
      <div
        style={{
          padding: 20,
          minWidth: 300,
          minHeight: 200,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ fontSize: 16, marginTop: 40, color: "#44546A" }}>
          Successfully signed up, verify your email to login
        </div>
        <button
          type="submit"
          className={classes.button}
          onClick={() => {
            history.push("/");
          }}
        >
          Return to homepage
        </button>
      </div>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit} style={{ flex: 1, overflow: "auto" }}>
      <div className={classes.formContainer}>
        <div className={classes.leftSide}>
          <FormInput
            formLabel={"First Name"}
            required={true}
            title={"firstName"}
            placeholder="Firstname"
            value={formik.values.firstName}
            type={"text"}
            handleChange={formik.handleChange}
            error={formik.errors.firstName}
          />
          <FormInput
            formLabel={"Last Name"}
            required={true}
            title={"lastName"}
            placeholder="Lastname"
            value={formik.values.lastName}
            type={"text"}
            handleChange={formik.handleChange}
            error={formik.errors.lastName}
          />
          <FormInput
            formLabel={"Role"}
            required={false}
            title={"role"}
            placeholder="Role"
            value={formik.values.role}
            type={"text"}
            handleChange={formik.handleChange}
            error={formik.errors.role}
            infoText={"Enter your current role"}
          />
          <FormInput
            formLabel={"Organisation"}
            required={true}
            title={"organisation"}
            placeholder="Organisation"
            value={formik.values.organisation}
            type={"text"}
            handleChange={formik.handleChange}
            error={formik.errors.organisation}
            infoText={"Select the organisation you work for"}
          />
        </div>
        <div className={classes.rightSide}>
          <FormInput
            formLabel={"Email"}
            required={true}
            title={"email"}
            placeholder="Email"
            value={formik.values.email}
            type={"email"}
            handleChange={formik.handleChange}
            error={formik.errors.email}
            infoText={"Enter your work email address"}
          />
          <FormInput
            formLabel={"Password"}
            required={true}
            title={"password"}
            placeholder="Password"
            value={formik.values.password}
            type={"password"}
            handleChange={formik.handleChange}
            error={formik.errors.password}
          />
          <FormInput
            formLabel={"Confirm Password"}
            required={true}
            title={"confirmPassword"}
            placeholder="Confirm password"
            value={formik.values.confirmPassword}
            type={"password"}
            handleChange={formik.handleChange}
            error={formik.errors.confirmPassword}
          />
          <button
            type="submit"
            className={classes.button}
            disabled={!formik.isValid || !formik.dirty}
          >
            Sign up
          </button>
          {signupError && (
            <div
              style={{
                margin: 20,
                padding: 20,
                borderRadius: 5,
                backgroundColor: "#F061A6",
                color: "white",
              }}
            >
              {signupError}
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

const SignupValidation = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .matches(/^\S+@\S+\.\S+$/)
    .required(),
  password: Yup.string()
    .required("Please Enter your password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Password does not match")
    .required("Confirm your password"),
  role: Yup.string(),
  organisation: Yup.string().required("Please enter your organisation name"),
});

// Wrap our form with the withFormik HoC
export const Signup = InnerForm;
