import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import { Button } from "@mui/material";
import { useModal } from "mui-modal-provider";
import { SignInModal } from "./Signin";

interface Props {
  mode: "light" | "dark";
}

export const UserMenuDropdown: React.FC<Props> = ({ mode }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { showModal } = useModal();

  return (
    <div
      style={{
        position: "relative",
        color: mode === "light" ? "white" : "#627592",
      }}
    >
      <PersonIcon
        color="inherit"
        style={{ cursor: "pointer" }}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <div
          style={{
            position: "absolute",
            right: 0,
            backgroundColor: "white",
            padding: "10px 16px",
            width: 140,
            borderRadius: 5,
            boxShadow: "2px 4px 16px 0px rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            color: "#627592",
          }}
        >
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => {
              const token = localStorage.getItem("token");
              if (token) {
                localStorage.removeItem("token");
                localStorage.removeItem("idToken");
                window.location.reload();
              } else {
                showModal(SignInModal, { title: "signin", signIn: "login" });
              }
            }}
          >
            {localStorage.getItem("token") ? "Sign out" : "Sign in"}
          </Button>
        </div>
      )}
    </div>
  );
};
