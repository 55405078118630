import React from "react";
import { Chart } from "./Chart";
import { ceil } from "lodash";
import { Metric } from "../http/metrics";
import { chasmColorMap, Chasms } from "./ChasmSelection";
import { CCGMetric } from "../http/ccg-metrics";
import { isCCGMetric } from "../http/metrics-helper";
import { CCGFeature, ICSFeature } from "../selectors/comparison";
import { Option } from "./OptionSelection";
import { CompareChart } from "./CompareChart";

const getData = (data: (Metric | CCGMetric)[], chasm: Chasms) => {
  return data
    .map((ics) => ({
      name: isCCGMetric(ics) ? ics.ccg_name : ics.ics_name,
      value: ceil(ics[chasm], 4),
    }))
    .sort((a, b) => (a.value <= b.value ? 1 : -1));
};

interface ChartsProps {
  data: (Metric | CCGMetric)[];
  chasm: Chasms;
  selectedFeature?: ICSFeature | CCGFeature;
  comparison: Option;
}

export const Charts: React.FC<ChartsProps> = ({
  data,
  selectedFeature,
  chasm,
  comparison,
}) => {
  const chasmData = getData(data, chasm);

  if (comparison.value === "nocomparison") {
    return (
      <Chart
        data={chasmData}
        variant={chasmColorMap[chasm].variant}
        selectedFeature={selectedFeature}
      />
    );
  } else {
    return (
      <CompareChart
        data={chasmData}
        variant={chasmColorMap[chasm].variant}
        selectedFeature={selectedFeature}
      />
    );
  }
};
