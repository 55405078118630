import "./App.css";
import { QueryParamProvider } from "use-query-params";
import { LandingPage } from "./pages/LandingPage";
import { MainPage } from "./pages/MainPage";
import { SignUpPage } from "./pages/SignUpPage";
import { AboutPage } from "./pages/AboutPage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ModalProvider from "mui-modal-provider";

function App() {
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <ModalProvider>
          <Switch>
            <Route exact path="/">
              <LandingPage />
            </Route>
            <Route exact path="/about">
              <AboutPage />
            </Route>
            <Route path="/app">
              <MainPage />
            </Route>
            <Route path="/signup">
              <SignUpPage />
            </Route>
          </Switch>
        </ModalProvider>
      </QueryParamProvider>
    </Router>
  );
}

export default App;
