import React from "react";
import { useHistory } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { Button } from "@mui/material";

interface Props {
  mode: "light" | "dark";
}

export const MobileMenuDropdown: React.FC<Props> = ({ mode }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const history = useHistory();

  return (
    <div
      style={{
        position: "relative",
        color: mode === "light" ? "white" : "#627592",
      }}
    >
      <MenuIcon
        color="inherit"
        style={{ cursor: "pointer" }}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <div
          style={{
            position: "absolute",
            backgroundColor: "white",
            padding: "10px 16px",
            borderRadius: 5,
            boxShadow: "2px 4px 16px 0px rgba(0,0,0,0.5)",
            width: 150,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            color: "#627592",
          }}
        >
          <Button color="inherit" onClick={() => history.push("/about")}>
            About ICE
          </Button>
          <Button
            color="inherit"
            target="_blank"
            href="https://www.independent.co.uk/news/health/nhs-patients-care-postcode-lottery-b1929279.html"
          >
            In the news
          </Button>
          <Button
            color="inherit"
            target="_blank"
            href="https://www.carnallfarrar.com/contact-us/"
          >
            Get in touch
          </Button>
        </div>
      )}
    </div>
  );
};
